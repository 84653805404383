.pageList {
}
.pageListItem {
  &__inner {
    margin: 0.5rem;
    text-align: left;
  }

  &__title {
    font-size: 1.8rem;
    font-family: $family-header;
    line-height: 1;
  }

  &__highlight {
    font-size: 0.8rem;
  }
}