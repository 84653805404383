.navBar__subMenu {
  transition: all 0.4s;
  max-height: 0;
  overflow: hidden;
  display: list-item;
}

.navBar__subItem:nth-last-child(1) {
  margin-bottom: 0;
}

a.navBar__subLink {
  padding-left: 2.5rem;
  @include media-breakpoint-up(lg) {
    padding-left: 3.5rem;
  }
  position: relative;
  // &:before {
  //   content: '• ';
  //   position: absolute;
  //   left: 1.5rem;
  //   @include media-breakpoint-up(lg) {
  //     left: 2rem;
  //   }
  // }
}