$themes: (
    default : ($color-red, $color-red-h),    
    red : ($color-red, $color-red-h),
    orange : ($color-orange, $color-orange-h),
    green : ($color-green, $color-green-h),
    blue : ($color-blue, $color-blue-h),
    yellow : ($color-yellow, $color-yellow-h),
    purple : ($color-purple, $color-purple-h),
    grey : ($color-grey, $color-grey-h)
    //lightblue : ($color-light-blue, $color-light-blue-h),
    //lightgreen : ($color-light-green, $color-light-green-h), 
);

@each $theme-code, $params in $themes{
    $color: nth($params, 1);
    $color2: nth($params, 2);
    div.body-content.theme--#{$theme-code}{
        .hamburger, .navBar, .navBar_nav a, .actions button{
            background-color: $color;
        }
        .navBar_nav a{   
            &:hover{
                background-color: $color2;
            }
        }
        .cms_item.htmlPanel{ 
            h1 b, .h1 b,
            h2 b, .h2 b,
            h3 b, .h3 b,
            h1 strong, .h1 strong,
            h2 strong, .h2 strong,
            h3 strong, .h3 strong,
            //dl, ol, ul,
            ul li::before,
            a:not(.s-button):not(.p-button):not(.button)
            {
                color: $color;
            }
            a.button{
                background-color: $color;
            }
        }
        div.theme__bar{
            background-color: $color;
        }
        .atdwSearch{
            div.town{
                background-color: $color;
                color: #ffffff;
            }
        }
        header.topNav{
            background-color: $color;
        }
        // .atdw__page__banerIconsBar{
        //     background-color: $color;
        // }

        .atdw__title strong, .productName{
            color: $color;
            font-weight: $weight-bold;
        }
        .atdwTile hr{
            background-image: linear-gradient(90deg,$color,transparent);
        }
        .btn-primary{
            background-color: $color;
            border-color: $color;
            &:hover{
                background-color: $color2;
                border-color: $color2;
            }
        }
        .atdw__map__filter .btn.selected{
            background-color: $color2;
            border-color: $color2;
        }
    }
    div.pageTile__j5.theme--#{$theme-code}{
        .pageTile__j5__content__details{
            //background-color: rgba($color, $alpha: 0.7) !important;
            background-color:$color;
            opacity: 0.7;
        }
    }
}