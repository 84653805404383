@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~react-day-picker/lib/style.css';

@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
@import './layout/hamburger';
@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';

@import './modules/youtube';

// components
@import '../components';

@import './site/index';

//aos
@import "~aos/src/sass/aos";

//Theme
@import './theme/theme';

.container{
    @include media-breakpoint-up(xl){
        max-width: 1140px;
    }
}

a.topNav__back svg{
    margin-top: -3px;
}

div.appInfo{
    text-align: center;
}

#weather-full{
    display: none !important;
}
#weather-small{
    display: block !important;
}

@include media-breakpoint-up(sm){
    #weather-full{
        display: block !important;
    }
    #weather-small{
        display: none !important;
    }
}

div.pageTile__bsyc{
    a.pageTile__bsyc__content:hover{
        .pageTile__bsyc__content__details.red{
            background-color: darken($color-red, 25%);
        }
        .pageTile__bsyc__content__details.orange{
            background-color: darken($color-orange, 25%);
        }
        .pageTile__bsyc__content__details.green{
            background-color: darken($color-green, 25%);
        }
        .pageTile__bsyc__content__details.blue{
            background-color: darken($color-blue, 25%);
        }   
        .pageTile__bsyc__content__details.yellow{
            background-color: darken($color-yellow, 25%);
        }
        .pageTile__bsyc__content__details.purple{
            background-color: darken($color-purple, 25%);
        }
        .pageTile__bsyc__content__details.grey{
            background-color: darken($color-grey, 25%);
        }
    }
}

.btn-primary{
    font-weight: $weight-bold;
}

.form-control.is-invalid{
    color: white;
}