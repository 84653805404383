
iframe.youtube, .ytvideo iframe {
  width: 100%;
}

.ytvideo{
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
}
.ytvideo iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}