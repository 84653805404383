.error-panel {
  background: white;
  padding: 3rem 0;
}

.error {
  margin: 1rem 0;
  color: red;
}

.errorCentered {
  position: fixed;
  padding: 3rem;
  width: 800px;
  max-width: 90vw;
  max-height: 90vw;
  background: white;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}