@import 'standard';
@import 'thin';

.bannerSize, .bannerSize--standard {
  @extend %bannerSize--standard;
}

.bannerWrapperSize--thin .bannerSize, .bannerSize--thin {
  @extend %bannerSize--thin;
}
