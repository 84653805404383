// overriding bootstrap variables in _boostrap-override.scss

$family-base: "proxima-nova", sans-serif;
$family-header: "proxima-nova", sans-serif;

$weight-thin: 100;
$weight-light: 300;
//$weight-regular: 400;
//$weight-medium: 500;
//$weight-semi-bold: 600;
$weight-bold: 700;
$weight-normal: $weight-light;

// colors - sister colours from bootrap
// $color-blue:    #007bff;
// $color-red:     #fe3a43;
// $color-orange:  #fd7e14;
// $color-green:   #28a745;

// more colors
$color-teal: #30b0c5;
$color-gold: #c78346;
$color-dark-gray: #252525;
$color-light-gray: #f7f7f7;
$color-black: black;
//$color-red: #ec1c23;

//theme
$color-red: #b84a4b;
$color-red-h: #8f0b10;
$color-orange: #f79552;
$color-orange-h: #c15200;
$color-green: #a1c288;
$color-green-h: #00612d;
$color-blue: #4cb2bd;
$color-blue-h: #004e83;
$color-yellow: #dbb079;
$color-yellow-h: #d88c00;
$color-purple: #7d3f60;
$color-purple-h: #7d3f60;
$color-grey: #373637;
$color-grey-h: #373637;
//$color-light-blue: #23a6df;
//$color-light-blue-h: #0071a3;
//$color-light-green: #30a74b;
//$color-light-green-h: #006d18;
//$color-dark-blue: #254273;
//$color-dark-blue-h: #254273;
$color-navy: #003774;
$color-navy2: #003773;
$color-navy-h: #002651;

// site colors
$primary-color: $color-red;
$font-color: $color-grey;
$font-color-white: #fff;
$header-color: $font-color;
$bg-color:#fff;

$link-color: $primary-color;
$link-hover-color: $color-red-h;

// top-bar
$top-bar-border: (mobile: 5px, desktop: 12px);
$top-bar-height: (mobile: 44px, desktop: 56px);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;