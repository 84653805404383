$breakpoints: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 3,
);
$margin: 30px;

.blogTiles {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display: flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  margin-top: 2rem;
  margin-bottom: 2rem;

  &__loadMore {
    margin-bottom: 2rem;
    .thinBtn {
      width: 100%;
      display: block;
      color: $primary-color;
      cursor: pointer;
      &:hover,
      &:focus {
        color: black;
      }
    }
  }

  &--productView .blogTile {
    @include media-breakpoint-up(xxl) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }
}

.blogTile {
  padding: $margin/2;

  @each $bp, $number in $breakpoints {
    @include media-breakpoint-up(#{$bp}) {
      flex: 0 0 100% / $number;
      max-width: 100% / $number;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background: #fff;
    color: black;
    box-shadow: 1px 1px 5px 0px #e0e0e0;
    line-height: 1.1;
    text-align: left;
    border-bottom: 5px solid #b84a4c;
    // padding: 8px;

    &:hover,
    &:focus {
      background: #b84a4c;
      text-decoration: none;
      .thinBtn {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
      }
      .red_line {
        background-color: #fff;
      }
      .blogTile__contentInner {
        // background-color: #b84a4c;
        color: #fff;
      }
      .post_date {
        color: #fff;
      }
      .blogTile__title {
        color: #fff;
      }
    }
  }

  &__bg {
    @include bg-img();
    @include img-ratio(100%);
    width: 100%;
    // z-index: -1;
    padding-top: 60%;
  }

  &__content {
    width: 100%;
  }

  &__contentInner {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    padding: 0 1rem 1rem;
  }

  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #b84a4c;
    line-height: 1.2;
    margin: 0.7rem 0.2rem;
    text-align: left;
  }

  &__desc {
    font-size: 0.9rem;
    line-height: 1.2;
    width: 100%;
    margin-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.red_line {
  width: 50%;
  height: 0.1rem;
  background-color: #b84a4c;
}
.post_date {
  color: #b84a4c;
  margin: 0.7rem 0;
}
.thinBtn {
  font-weight: $weight-bold;
  text-align: center;
  border: 1px solid #b84a4c;
  display: inline-block;
  font-family: $family-header;
  background: $primary-color;
  line-height: 1;
  color: white;
  transition: all 0.3s;
  border-radius: 5px;
  padding: 0.4rem 1rem;
  width: 40%;
  font-size: 0.7rem;
  &:hover,
  &:focus {
    text-decoration: none;
    background: $link-hover-color;
  }
}
