%footer-font {
  font-size: 0.7rem;
  color: white;

  &:hover,
  &:focus {
    color: white;
  }
}

.footerLinks {

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;
    
    &.title{
      @include media-breakpoint-down(sm){
        display: block;
        margin: 0px;
        padding: 0px;
        border: none;
      }
    }
  }

  li {
    margin: 0 .7rem 0 0;
    padding: 0 .7rem 0 0;
    list-style: none;
    border-right: 1px solid lighten($font-color, 50);
    @extend %footer-font;

    &:last-of-type {
      border-right: 0;
      @include media-breakpoint-down(sm){
        margin: 0px;
        padding: 0px;
      }
    }
    
  }

  a {
    @extend %footer-font;
  }
}