//@mixin tile--titleDesc($breakpoints:(xs:1, sm:1, md:1, lg:1, xl:1), $margin: 20px, $ratio: 56.25%) {
@mixin tile--titleDesc(
  $breakpoints:(xs:1, sm:1, md:1, lg:1, xl:1),
  $margin: 20px,
  $ratio: 56.25%) {
  margin-left: -$margin/2;
  margin-right: -$margin/2;
  display:flex;
  justify-content: flex-start; //center;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .tile {
    padding: $margin/2;
    @each $bp, $number in $breakpoints {
      @include media-breakpoint-up(#{$bp}) {
        flex: 0 0 100%/$number;
        max-width: 100%/$number;
      }
    }
  }

  .tile__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height:100%;
    cursor: pointer;
    position: relative;
    flex-wrap: wrap;
  }

  .tile__bg {
    @include bg-img();
    flex: 1 1 300px;
    //@include img-ratio($ratio);
    @include img-ratio(70%);
    @include media-breakpoint-up(sm){
      @include img-ratio(38%);
    }
    @include media-breakpoint-up(lg){
      @include img-ratio(30%);
    }
  }
  
}