
.cmsPage {
  &--BlogPost {
    .htmlPanel {
      text-align: left;
    }
    
    .bottomPanel .htmlPanel {
      text-align: center;
    }

    .instagram-media {
      max-width: 720px !important;
    }
    .element-invisible {
      display:none;
    }
  }
}