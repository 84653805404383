$top-bar-z-index: 1000 !default;
$top-bar-height: (mobile: 2rem, desktop: 2rem) !default;

.topNav__logo {
  @extend %topNavMarginTop;
  position: fixed;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  z-index: $top-bar-z-index + 2;
  img {
    width: 160px;
  }

  // @include media-breakpoint-up(sm){
  //   left: 100px;
  // }

  @include media-breakpoint-up(lg) {
    //top: 3px;
    img {
      width: 210px;
    }
  }

}

.topNav__skewBar {
  position: fixed;
  @extend %topNavMarginTop;
  background: linear-gradient(rgba(#fff, 0.2), rgba(#fff, 0));
  width: 100%;
  height: 100%;
  left: 110px;
  transform: skewX(-12deg) translateX(-100%);
  transform-origin: top left;
  z-index: $top-bar-z-index + 1;
  pointer-events: none;

  @include media-breakpoint-up(lg) {
    left: 330px;
  }
}