.stores {
  //max-width: 1000px;
  margin: 2rem auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-start;

  li {
    margin-bottom: 1.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    flex: 0 1 25%;
    @include media-breakpoint-down(md) {/*<992*/ flex: 0 1 33%}
    @include media-breakpoint-down(sm) {/*<768*/}
    @include media-breakpoint-down(xs) {/*<576*/ flex: 0 1 50%}
  }

  img {
    width: auto;
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  figcaption {
    display: block;
    margin-top: 1rem;
  }

  .icon {
    @include bg-img();
    background-image: url('/assets/icons/cart-red.svg');
    width: rem(20);
    height: rem(20);
    display: inline-block;
  }

  a {
    transition: all 0.3s;
    &:hover {
      text-decoration: none;

      .icon {
        background-image: url('/assets/icons/cart.svg');
      }
    }
  }
}
