$top-bar-z-index: 1000 !default;

.topNav {
  transition: all 0.5s ease-in-out;
  position: fixed;
  //background: #fff;
  background-color: transparent;
  z-index: $top-bar-z-index;
  height: map-get($top-bar-height, mobile);
  top: 0;
  width: 100%;
  font-size: 0.75rem;
  // &:before {
  //   content: '';
  //   background: rgba(#fff, 0.1);
  //   width: 100%;
  //   height: 100%;
  //   transform: skewX(-12deg) translateX(100%);
  //   pointer-events: none;
  //   position: absolute;
  //   right: 13rem;
  // }
  
  //for scroll
  .topNav__logo{
    img {
      transition: all 0.5s ease-in-out;
      //width: 260px;
      width: 240px;
      @include media-breakpoint-up(lg) {
        width: 360px;
      }
    }
    
  }

  &.scrolled{
    //background-color: $color-light-gray;
    .topNav__logo{
      img {
        width: 225px;
        @include media-breakpoint-up(lg) {
          width: 288px;
        }
      }
    }
  }
  //for scroll

  @include media-breakpoint-up(lg) {
    height: map-get($top-bar-height, desktop);
  }
  // div.theme__bar{
  //   //background-color: red;
  //   padding-top: 1.3rem;
  //   width: 100%;
  // }
}

a.topNav__back {
  position: absolute;
  left: 1rem;
  line-height: map-get($top-bar-height, mobile);
  //line-height: 1.8;
  top: 0;
  color: white;
  &:hover {
    color: $color-navy2;
    text-decoration: none;
  }
  @include media-breakpoint-up(lg) {
    line-height: map-get($top-bar-height, desktop);
    //line-height: 1.8;
  }
}

.topNav__siteSwitch {
  position: absolute;
  right: 1.5rem;
  line-height: map-get($top-bar-height, mobile);
  @include media-breakpoint-up(lg) {
    right: 2rem;
    line-height: map-get($top-bar-height, desktop);
  }
}

// .topNav__men {
//   color: white;
//   letter-spacing: 2px;
//   &:hover {
//     color: white;
//     text-decoration: none;
//   }
// }

// .topNav__women {
//   color: $primary-color;
//   font-weight: bold;
//   letter-spacing: 2px;
// }

.topNav__sep {
  display: inline-block;
  padding: 0 1rem;
  color: white;
}