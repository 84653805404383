.invalid {
  font-size: 85%;
  color: red;
}

// overriding bootrap invalid text box (remove x icon)
.was-validated .form-control:invalid, .form-control.is-invalid {
  background:none;
}

.form-check-input {
  margin-top: 0.6rem;
}

.form__actions {
  margin: 0.5rem 0;
}

label.label {
  display: block;
}

.listMin3 .form-check-inline {
  min-width: 30%;
}

@import 'steps';