.banner-item {
  max-width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.banner_item__text,
.banner-item__link {
  font-family: $family-header;
  color: #fff;
  font-size: 1.4rem;
  @include media-breakpoint-up(lg) {
    font-size: 23px;
  }
}

.banner_item__text {
  background: rgba(#000, 0.6);
  //text-align: center;
  text-align: left;
  padding: 0.5rem 0 0.7rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;

  .banner_item__text__warpper {
    max-width: 960px;
    margin: auto;
    .banner-item__title {
      display: inline-block;
      padding: 0px 2.5rem;
      //background-color: $primary-color;
      line-height: 22px;
      padding-bottom: 0px;
      padding-top: 3px;
      border-radius: 10px;
      //font-weight: $weight-bold;
      padding-left: 0px;
      padding-right: 0px;
      text-transform: uppercase;
      @include media-breakpoint-up(lg) {
        line-height: 43px;
      }
    }
  }
}

.banner_item__short_caption {
  font-weight: $weight-light;
  font-size: 0.7rem;
  font-family: $family-base;
  margin-top: -0.3rem;
}
.banner-item__link {
  &:hover,
  &:focus {
    color: #fff;
  }
}
